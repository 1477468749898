import React, { Component } from "react"
import FindYourTable from "./FindYourTable"
import ViewYourBill from "./ViewYourBill"
import ThanksForPaying from "./ThanksForPaying"
import BillNotFound from "./BillNotFound"
import { fetchCustomerTab } from "./ApiRequests"

class AppState extends Component {
  constructor(props){
    super(props);
    this.state = {
      view: "FindYourTable",
      tableId: "", 
      customerTab: {}
    }
    this.updateTableId = this.updateTableId.bind(this);
    this.submitTableId = this.submitTableId.bind(this);
    this.onPaymentComplete = this.onPaymentComplete.bind(this);
  }

  updateTableId(event){
    const tableId = event.target.value
    this.setState({ tableId });
  }

  submitTableId(){
    fetchCustomerTab({tableId: this.state.tableId})
      .then(({outletName, ...customerTab}) =>{
      this.setState((state) => ({
        ...state,
        outletName,
        customerTab,
        view: "ViewYourBill",
      }))
      })
      .catch(() => {
        this.setState((state) => ({
          view: "BillNotFound",
        }))
      })
  }

  onPaymentComplete(){
    this.setState({
      view: "ThanksForPaying"
    })
  }

  render(){
    if(this.state.view === "FindYourTable"){
      return(
        <FindYourTable
          tableId={this.state.tableId}
          updateTableId={this.updateTableId}
          submitTableId={this.submitTableId}
        />
      );
    }
    else if(this.state.view === "ViewYourBill"){
      return(
        <div>
          <ViewYourBill
            customerTab={this.state.customerTab}
            onPaymentComplete={this.onPaymentComplete}
            outletName={this.state.outletName }
          />
        </div>
      );
    } else if(this.state.view === "BillNotFound"){
      return (
        <BillNotFound 
          onTryNewId={ () => { this.setState({ view: "FindYourTable" }) }}/>
      )

    }
    return (<ThanksForPaying/>);
  }
}

export default AppState;
