import React from "react" 
import styled from 'styled-components';
import {
  PrimaryTitle, SubmitButton, CenterBox
} from "./styles"


const BillNotFound = ({ onTryNewId }) => { 
  return(
    <CenterBox>
      <PrimaryTitle> Unable to Find Your Bill </PrimaryTitle>
        <div> 
          <SubmitButton type="button" onClick={onTryNewId}>Try a different Id</SubmitButton>
        </div>
    </CenterBox>
  )
}
export default BillNotFound;
