
const baseUrlEnv = process.env.REACT_APP_HOST_NAME
export const getJsonRequest = ({ url }) => {
  const myInit = {
    method: "GET",
  };

  return fetch(url, myInit)
    .then(response => response.json())
};

const mapOrderItemModGroup = (i) => i
const mapOrderItems = ({ item_name, multiplier, subtotal}) => ({
  itemName: item_name,
  taxInclusive: Number(subtotal),
  multiplier,
})
const mapOrders = (order) => ({
  orderItems: order.order_items.map(mapOrderItems)
})

const mapCustomerTabResponse = ({customer_tab: { outlet_name, order_items, ...customerTab}}) => { 
 return {
  outletName: outlet_name,
  orderItems: order_items.map(mapOrderItems),
}
}
export const fetchCustomerTab = ({tableId }) => {
  return getJsonRequest({ url: `${baseUrlEnv || ""}/regulr_pay_api/customer_tab/${tableId}` })
    .then(mapCustomerTabResponse)
}
