import React, { Component } from 'react';
import styled from 'styled-components';
import logo from './logo.svg';
import './App.css';
import AppState from "./AppState"

import { 
  HeaderBar, BrandName, Container, PrimaryTitle, Footer
} from "./styles"



class App extends Component {
  render() {
    return (
      <Container>
        <HeaderBar className="App-header">
          <BrandName>
            Regulr Pay
          </BrandName>

        </HeaderBar>
        <AppState/>
        <Footer>
          powered by <a href="https://posbosshq.com"> posBoss</a> and <a href="https://regulrapp.com"> Regulr </a>
        </Footer>
        </Container>

    );
  }
}

export default App;
