import React from "react" 
import styled from 'styled-components';
import {
  PrimaryTitle, SubmitButton, CenterBox
} from "./styles"

const TextField = styled.input`
  padding: 10px;
  border: 2px solid #999;
  font-size: 16px;
  text-align: center;
  border-radius: 6px;
`

const FindYourTable = ({ tableId, updateTableId, submitTableId }) => { 
  return(
    <CenterBox>
      <form onSubmit={(e) => { e.preventDefault(); submitTableId(e) }}>
        <PrimaryTitle> Find your table </PrimaryTitle>
        <TextField 
          placeholder="9338"
          onChange={updateTableId}
          value={tableId}
        />
        <div> 
          <SubmitButton type="button" onClick={submitTableId}>Find Your table </SubmitButton>
        </div>
      </form>
    </CenterBox>
  )
}
export default FindYourTable;
