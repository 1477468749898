import React from "react"
import StripeCheckout from "react-stripe-checkout";
import logo from "./logo-cloud-red.png"
import styled from 'styled-components';
import {
  PrimaryTitle, SubmitButton, CenterBox
} from "./styles"

const RightText = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: normal;
`
const LeftText = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: normal;
`


const Row = styled.div`
  display: grid;
  padding: 10px;
  margin: 0px;
  border-bottom: solid 2px grey;
  grid-template-columns: 50% 50%;
`

const Bill = styled(CenterBox)`
  padding: 0px
  ${Row}:last-child {
    border-bottom: none;
  }

`

const CenterAlignText = styled.div`
  text-align: center;
`

const OrderItemRow = ({ multiplier, itemName, taxInclusive, ...props }) => {
  return (
    <Row>
      <LeftText> {multiplier} x {itemName} </LeftText>
      <RightText> ${taxInclusive}</RightText>
    </Row>
  )
}

const ViewYourBill = ({ outletName, customerTab, onPaymentComplete, ...props }) => {
  const sum = (a,b) => a + b
  const totalBill = customerTab.orderItems
    .map(({taxInclusive, multiplier}) => taxInclusive * multiplier)
    .reduce(sum)
  return(
    <React.Fragment>
      <Bill>
        <Row>
          <PrimaryTitle>Your Bill at {outletName} </PrimaryTitle>
        </Row>
        { customerTab.orderItems.map((item, index) => (
          <OrderItemRow {...item } key={index} />
        ))
        }
        <Row>
          <LeftText><strong>Total Bill </strong></LeftText>
          <RightText><strong>${totalBill}</strong></RightText>
        </Row>
      </Bill>
      <CenterAlignText>
        <StripeCheckout
          name="RegulrPay"
          ComponentClass="div"
          image={logo}
          panelLabel="Pay your bill"
          amount={1250}
          currency="NZD"
          stripeKey="pk_test_TYooMQauvdEDq54NiTphI7jx"
          email="pb@posbosshq.com"
          billingAddress={false}
          token={()=>{}}
          closed={onPaymentComplete}
        >
          <SubmitButton> Pay Now </SubmitButton>
        </StripeCheckout>
      </CenterAlignText>
    </React.Fragment>
    )
}
export default ViewYourBill;
