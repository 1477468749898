import React from "react" 
import styled from 'styled-components';
import {
  PrimaryTitle, SubmitButton, CenterBox
} from "./styles"

const TextField = styled.input`
  padding: 10px;
  border: 2px solid #999;
  border-radius: 6px;
`

const ThanksForPaying = ({ props }) => { 
  return(
    <CenterBox>
        <PrimaryTitle> Thanks for paying</PrimaryTitle>
        👍
    </CenterBox>
  )
}
export default ThanksForPaying;
