import styled from 'styled-components';
export const posbossRed = "#F9423A"

export const HeaderBar = styled.header`
  background-color: #282c34;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  font-weight: bold;
  color: ${posbossRed};
  font-size: 20px;
  padding: 3px;
  height: 30px;
  background-color: white;
  border-bottom: solid 3px ${posbossRed};
`
export const BrandName = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 300px
`
export const Container = styled.div`
  font-family: 'Open Sans', sans-serif; 
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-top: 60px;
  padding-bottom: 30px
`

export const PrimaryTitle = styled.h4`
  color: ${posbossRed}
  margin-top: 5px
`

export const CenterBox = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background: white
  padding: 6px;
  width: 300px;
  border-radius: 6px;
`

export const SubmitButton = styled.button`
  margin-top:32px;
  margin-bottom:15px;
  padding: 10px 20px;
  color: ${posbossRed};
  font-size: 14px;
  font-weight: 600px;
  border-radius: 4px;
  border: none;
  background-color: white;
  border: solid 2px ${posbossRed}
`
export const Footer = styled.footer`
  color: ${posbossRed};
  text-align: center;

  margin-top: 50px;
  font-size: 12px;
  a  {
    color: ${posbossRed}
  }
`
